.container {
  width: 100%;
  height: 13%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

.container::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #e1e1e1;
  left: 0;
  top: 0;
}
.container svg {
  margin: 8px;
  cursor: pointer;
}

.message {
  flex: 1;
  max-height: 135px;
  margin: 8px;
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
  padding: 8px 12px;
  outline: none;
  border: 1px #e1e1e1 solid;
  border-radius: 20px;
}

.message:focus {
  border-color: rgb(25, 118, 210);
  box-shadow: 0 0 2px rgb(25, 118, 210);
}
