.container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  margin-bottom: 2px;
  margin-right: auto;
  position: relative;
}

.Nodo {
  max-width: 100px;
  height: 38px;
  position: absolute;
  content: "";
  top: -15px;
  left: 45px;
}

.mini__logo,
.space {
  width: 40px;
}

.texto {
  font-size: 0.7375rem;
  margin: 0;
  margin-left: 4px;
  font-style: italic;
}

.texto2 {
  font-size: 0.7375rem;
  margin: 0;
  margin-left: 5px;
  font-style: italic;
}

.message {
  background-color: #e1e1e1;
  padding: 8px 12px;
  border-radius: 4px 20px 20px 4px;
  margin-left: 5px;
  max-width: 170px;
}

.message p {
  font-size: 0.9375rem;
  margin: 0;
  word-wrap: break-word;
  word-break: normal;
  text-overflow: clip;
  white-space: pre-line;
}

.message p a {
  font-size: 0.9rem;
  color: blue;
  text-decoration: none;
  margin: 0;
  font-weight: 500;
}

.message img {
  width: 100%;
  border-radius: 4px 20px 20px 4px;
  cursor: pointer;
}


.last {
  margin-bottom: 10px;
}

.first_message {
  border-top-left-radius: 20px;
}

.last_message {
  border-bottom-left-radius: 20px;
}

.unique {
  margin-bottom: 10px;
}

.unique_message {
  border-radius: 20px;
}
