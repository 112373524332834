.container {
  width: 100%;
  max-width: 338px;
  height: 455px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  border: 1px #666 solid;
  padding: 1px;
  position: relative;
  z-index: 9999;
}

.animation_slidein {
  animation-duration: 1s;
  bottom: 20px;
  animation-name: slidein;
  animation-timing-function: ease-in-out;
  top: auto !important;
}

.animation_slideout {
  animation-duration: 1s;
  bottom: -460px;
  animation-name: slideout;
  animation-timing-function: ease-in-out;
  top: auto !important;
}

.slidein {
  bottom: 0px;
}

.slideout {
  bottom: -460px;
  pointer-events: none;
  display: none !important;
}

@keyframes slidein {
  from {
    bottom: -460px;
  }
  to {
    bottom: 20px;
  }
}

@keyframes slideout {
  from {
    bottom: 20px;
  }
  to {
    bottom: -460px;
  }
}

/* @keyframes slideinBefore {
  from {
    margin-left: 110%;
  }

  50% {
    margin-left: 0%;
  }

  to {
    margin-left: 0%;
  }
}

.content::before {
  content: '';
  width: 328px;
  height: 5px;
  border-radius: 40%;
  background-color: rgba(0, 0, 0, 0.5);
  filter: blur(1px);
  bottom: -12px;
  right: 5px;
  position: absolute;
  animation-duration: 2s;
  animation-name: slideinBefore;
  animation-timing-function: ease-in-out;
  margin-left: 0%;
  margin-top: 0%;
} */
