#root_chatbot_react {
  width: 100%;
  max-width: 342px;
  height: auto;
  position: fixed;
  right: 120px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  z-index: 9999;
  transition: right 0.5s ease;
}

.initial__chatbot {
  bottom: -460px;
}

#root_icon_react {
  position: fixed;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  right: 20px;
  bottom: 20px;
  z-index: 9998;
  transition: right 0.5s ease;
}

#root_icon_react > img {
  border-radius: 100%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 1px;
  border: 2px #333 solid;
}

#root_icon_react::before {
  content: '';
  width: 66px;
  height: 5px;
  position: absolute;
  bottom: 0px;
  right: 0.5px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  filter: blur(1px);
}

@media screen and (max-width: 600px) {
  #root_chatbot_react {
    right: 2%;
  }
  #root_icon_react {
    right: 2%;
    bottom: 3%;
  }
}
