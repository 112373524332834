.container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 2px;
}

.message {
  background-color: #2983fc;
  padding: 8px 12px;
  border-radius: 20px 4px 4px 20px;
  max-width: 200px;
}

.message p {
  font-size: 0.9rem;
  color: white;
  margin: 0;
  word-wrap: break-word;
  word-break: normal;
  text-overflow: clip;
  white-space: pre-line;
}

.message p a {
  font-size: 0.9rem;
  color: white;
  text-decoration: none;
  margin: 0;
  font-weight: 500;
}

.first {
  margin-top: auto;
}

.last {
  margin-bottom: 10px;
}

.first_message {
  border-top-left-radius: 20px;
}

.last_message {
  border-bottom-left-radius: 20px;
}

.unique {
  border-radius: 20px;
  margin-bottom: 10px;
}
