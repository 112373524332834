.container {
  width: 200px;
  max-width: 200px;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
}

.container svg {
  margin-left: 5px;
  cursor: pointer;
}

.container h3 {
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
  margin-left: 10px;
  max-width: 160px;
  font-size: 1rem;
  cursor: pointer;
  word-wrap: break-word;
  word-break: normal;
  text-overflow: clip;
  overflow: hidden;
}
