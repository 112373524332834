.container {
  width: 100%;
  height: 13%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  /* border-bottom: 1px #555 solid; */
}

.container svg {
  margin: 8px;
  cursor: pointer;
}

.container::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #e1e1e1;
  left: 0;
  bottom: 0;
}

.title {
  flex: 1;
  margin: 8px 8px 8px calc(8px + 19px);
  display: flex;
  justify-content: center;
}

.title p {
  letter-spacing: 1px;
  font-size: 1.2rem;
}
