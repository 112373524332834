.container {
  height: 74%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  margin: 0 15px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 8px;
}

.space_blank {
  margin-top: auto;
}
